import { HeadProps } from "gatsby";
import SEOHead, { initHeadLanguage } from "../components/old/seo_head";
import Manifesto from "../features/manifesto";
import { l_manifesto } from "../features/manifesto/locales";

export default Manifesto;

export const Head = (headProps: HeadProps) => {
  initHeadLanguage(headProps);

  return (
    <SEOHead
      title="Manifesto"
      description={l_manifesto("seo.description")}
      headProps={headProps}
    />
  );
};
