import { Box } from "@mui/material";
import RolesMosaic from "./RolesMosaic";
import RotatingWords from "./RotatingWords";
import Title from "./Title";

const ManyDefinitions = () => {
  return (
    <Box>
      <Title />
      <RotatingWords />
      <RolesMosaic />
    </Box>
  );
};

export default ManyDefinitions;
