import { Box } from "@mui/material";
import CardsSection from "./CardsSection";
import Title from "./Title";

const ScrollingCards = () => {
  return (
    <Box>
      <Title />
      <CardsSection />
    </Box>
  );
};

export default ScrollingCards;
