import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import Description from "./Description";
import Title from "./Title";
import { useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

//@ts-ignore
import img from "../../images/project-believers.png";

const ProjectBelievers = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    gsap.to(".projectBelieversOrangeLemon", {
      scrollTrigger: {
        trigger: ".projectBelieversOrangeLemon",
        scrub: 2,
      },
      x: -100,
      y: -80,
      rotate: isOverMd ? -80 : 120,
    });

    gsap.to(".projectBelieversOrangeLemon2", {
      scrollTrigger: {
        trigger: ".projectBelieversOrangeLemon2",
        scrub: 2,
      },
      x: -50,
      y: -100,
      rotate: 210,
    });

    gsap.to(".projectBelieversBlueCircle", {
      scrollTrigger: {
        trigger: ".projectBelieversBlueCircle",
        scrub: 2,
      },
      x: -20,
      y: 80,
    });

    gsap.fromTo(
      ".projectBelieversMainPhotos",
      {
        backgroundPosition: "40% 10%",
      },
      {
        scrollTrigger: {
          trigger: ".projectBelieversMainPhotos",
          scrub: 2,
        },
        backgroundPosition: "40% 90%",
      }
    );
  }, [isOverMd]);

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        marginBottom: { md: "280px", xs: "100px" },
      }}
    >
      <Stack
        sx={{
          maxWidth: "1800px",
          width: "90%",
          margin: "auto",
          flexDirection: { md: "row", xs: "column" },
          gap: { lg: "64px", md: "32px", xs: "0px" },
          boxSizing: "border-box",
          justifyContent: "space-between",
          alignItems: "flex-start",
          position: "relative",
        }}
      >
        <Title />
        <Description />

        <Box
          sx={{
            position: "absolute",
            width: { xs: "36px", md: "50px", lg: "60px" },
            top: { md: "-20%", xs: "-5%" },
            right: 0,
            transform: { md: "rotate(-140deg)", xs: "rotate(150deg)" },
          }}
          className="projectBelieversOrangeLemon"
        >
          <StaticImage src="../../images/orange-lemon.svg" alt="" width={60} />
        </Box>
      </Stack>
      {isOverMd && (
        <Box
          sx={{
            position: "relative",
            width: "90%",
            margin: "auto",
            marginTop: "120px",
            maxWidth: "1800px",
          }}
        >
          <Box
            sx={{
              borderRadius: "24px",
              // overflow: "hidden",
              margin: "auto",
              // backgroundColor: "whitesmoke",
              backgroundImage: `url(${img})`,
              backgroundSize: "120%",
              backgroundPosition: "40% 50%",
              width: "100%",
              aspectRatio: "1 / 0.41",
            }}
            className="projectBelieversMainPhotos"
          />
          <Box
            sx={{
              position: "absolute",
              width: "30px",
              top: "-60px",
              left: "60px",
            }}
            className="projectBelieversBlueCircle"
          >
            <StaticImage src="../../images/blue-circle.svg" alt="" width={30} />
          </Box>
          <Box
            sx={{
              position: "absolute",
              width: { md: "50px", lg: "60px" },
              bottom: "100px",
              right: "-40px",
              transform: "rotate(130deg)",
            }}
            className="projectBelieversOrangeLemon2"
          >
            <StaticImage
              src="../../images/orange-lemon.svg"
              alt=""
              width={60}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ProjectBelievers;
