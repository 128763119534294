import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { useEffect } from "react";
import TechnologiesGrid from "./TechnologiesGrid";
import Title from "./Title";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const ResearchAndDevelopment = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    if (isOverMd) {
      gsap.to(".RandDBlueLemon", {
        scrollTrigger: {
          trigger: ".RandDBlueLemon",
          scrub: 2,
        },
        rotate: 10,
        x: 80,
        y: 120,
      });
      gsap.to(".RandDPurpleCircle", {
        scrollTrigger: {
          trigger: ".RandDPurpleCircle",
          scrub: 2,
        },
        x: 50,
        y: -140,
      });
      gsap.to(".RandDBicolorSquare", {
        scrollTrigger: {
          trigger: ".RandDBicolorSquare",
          scrub: 2,
        },
        rotate: -80,
        x: -40,
        y: 100,
      });
    }
  }, [isOverMd]);
  return (
    <Stack
      sx={{
        maxWidth: "1800px",
        flexDirection: { md: "row", xs: "column" },
        width: "90%",
        margin: "auto",
        paddingBottom: { md: "440px", xs: "144px" },
        // backgroundColor: "oldlace",
        position: "relative",
        gap: { xs: "48px", md: "24px", lg: "32px" },
        justifyContent: "space-between",
      }}
    >
      <Title />
      <TechnologiesGrid />
      {isOverMd && (
        <>
          <Box
            sx={{
              position: "absolute",
              width: "60px",
              left: 0,
              bottom: "40%",
              transform: "rotate(-130deg)",
            }}
            className="RandDBlueLemon"
          >
            <StaticImage alt="" src="../../images/blue-lemon.svg" width={60} />
          </Box>
          <Box
            sx={{
              position: "absolute",
              width: "30px",
              right: "100px",
              bottom: "20%",
              transform: "rotate(40deg)",
            }}
            className="RandDBicolorSquare"
          >
            <StaticImage
              alt=""
              src="../../images/bicolor-square.svg"
              width={30}
            />
          </Box>
          <Box
            sx={{
              position: "absolute",
              width: "24px",
              left: "25%",
              top: "30%",
              transform: "rotate(40deg)",
            }}
            className="RandDPurpleCircle"
          >
            <StaticImage
              alt=""
              src="../../images/purple-circle.svg"
              width={24}
            />
          </Box>
        </>
      )}
    </Stack>
  );
};

export default ResearchAndDevelopment;
