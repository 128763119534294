/** @jsxImportSource @emotion/react */
import { Box } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect } from "react";

const Decorations = () => {
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    gsap.to(".headerOrangePill", {
      scrollTrigger: {
        trigger: ".headerDecorationsContainer",
        scrub: 2,
        start: "top 20%",
        end: "bottom 20%",
      },
      y: -100,
    });
    gsap.to(".headerBlueLemon", {
      scrollTrigger: {
        trigger: ".headerDecorationsContainer",
        scrub: 2,
        start: "top 20%",
        end: "bottom 20%",
      },
      y: 120,
      x: 50,
      rotate: 196,
    });
    gsap.to(".headerOrangeCircle", {
      scrollTrigger: {
        trigger: ".headerDecorationsContainer",
        scrub: 2,
        start: "top center",
        end: "bottom 20%",
      },
      y: -60,
      x: -80,
    });
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        // backgroundColor: "greenyellow",
      }}
      className="headerDecorationsContainer"
    >
      <Box
        sx={{
          width: { lg: "230px", md: "180px" },
          // backgroundColor: "pink",
          position: "absolute",
          bottom: 40,
          left: 0,
          transform: "rotate(-90deg)",
        }}
        className="headerOrangePill"
      >
        <StaticImage
          src={"../../images/orange-pill.svg"}
          alt={""}
          layout={"constrained"}
          width={200}
        />
      </Box>

      <Box
        sx={{
          width: { lg: "190px", md: "160px" },
          position: "absolute",
          bottom: "45%",
          right: 0,
          transform: "rotate(137deg)",
        }}
        className="headerBlueLemon"
      >
        <StaticImage
          src={"../../images/blue-lemon.svg"}
          alt={""}
          layout={"constrained"}
          width={200}
        />
      </Box>

      <Box
        sx={{
          width: { lg: "100px", md: "90px" },
          // backgroundColor: "peachpuff",
          position: "absolute",
          top: 0,
          left: "40%",
        }}
        className="headerOrangeCircle"
      >
        <StaticImage
          src={"../../images/orange-circle.svg"}
          alt={""}
          layout={"constrained"}
          width={120}
        />
      </Box>
    </Box>
  );
};

export default Decorations;
