import { Box } from "@mui/material";
import { l_manifesto } from "../../locales";
import ManifestoTypography from "../../theme/ManifestoTypography";

const Title = () => {
  return (
    <Box
      sx={{
        width: { lg: "45%", md: "50%", xs: "100%" },
        maxWidth: { lg: "550px", md: "500px", xs: "unset" },
        boxSizing: "border-box",
        flexShrink: 0,
      }}
    >
      <ManifestoTypography variant="title1">
        {l_manifesto("researchAndDevelopment.title")}
      </ManifestoTypography>
    </Box>
  );
};
export default Title;
