import { Box, Typography } from "@mui/material";
import { l_manifesto } from "../../locales";
import ManifestoTypography from "../../theme/ManifestoTypography";

const Title = () => {
  return (
    <Box
      sx={{
        width: { lg: "55%", md: "50%", xs: "100%" },
        maxWidth: { lg: "600px", md: "400px", xs: "unset" },
        boxSizing: "border-box",
        marginBottom: { md: 0, xs: "16px" },
      }}
    >
      <ManifestoTypography
        dangerouslySetInnerHTML={{
          __html: l_manifesto("noEndPoint.title"),
        }}
        variant="title1"
      ></ManifestoTypography>
    </Box>
  );
};
export default Title;
