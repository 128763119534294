import { Box } from "@mui/material";
import { useEffect } from "react";
import { l_manifesto } from "../../locales";
import ManifestoTypography from "../../theme/ManifestoTypography";

const Title = () => {
  return (
    <Box
      sx={{
        width: "90%",
        margin: "auto",
        maxWidth: "1800px",
        marginBottom: { md: "80px", xs: "24px" },
      }}
    >
      <ManifestoTypography
        variant="overtitle"
        sx={{ marginBottom: { md: "32px", xs: "8px" } }}
      >
        {l_manifesto("manyDefinitions.overtitle")}
      </ManifestoTypography>
      <ManifestoTypography
        variant="title1"
        sx={{ maxWidth: { md: "450px", lg: "550px", xs: "unset" } }}
      >
        {l_manifesto("manyDefinitions.title")}
      </ManifestoTypography>
    </Box>
  );
};

export default Title;
