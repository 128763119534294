/** @jsxImportSource @emotion/react */
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { LocalizedLink } from "../../../../components/LocalizedLink";
import { l_manifesto } from "../../locales";
import ButtonDotIcon from "../../theme/ButtonDotIcon";
import ManifestoTypography from "../../theme/ManifestoTypography";
import { useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

//@ts-ignore
import img from "../../images/project-believers.png";

const Description = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    gsap.fromTo(
      ".projectBelieversMainPhotos",
      {
        backgroundPosition: "40% 0%",
      },
      {
        scrollTrigger: {
          trigger: ".projectBelieversMainPhotos",
          scrub: 2,
        },
        backgroundPosition: "40% 70%",
      }
    );
  }, [isOverMd]);

  return (
    <Box
      sx={{
        flex: 1,
        boxSizing: "border-box",
        display: { md: "block", xs: "flex" },
        flexDirection: "column",
        maxWidth: { md: "900px", xs: "unset" },
      }}
    >
      <ManifestoTypography
        variant="body1"
        // sx={{ color: "rgba(0,0,0, .6)" }}
        dangerouslySetInnerHTML={{
          __html: l_manifesto("projectBelievers.description"),
        }}
      ></ManifestoTypography>
      {!isOverMd && (
        <Box
          sx={{
            marginTop: "24px",
            borderRadius: "24px",
            overflow: "hidden",
            backgroundImage: `url(${img})`,
            backgroundSize: "200%",
            backgroundPosition: "50% 50%",
            backgroundRepeat: "no-repeat",
            width: "100%",
            aspectRatio: "1/ 0.68",
          }}
          className="projectBelieversMainPhotos"
        ></Box>
      )}
      <a
        href="https://dn6665mnefe.typeform.com/to/OSLrZWJc"
        target={"_blank"}
        css={{ textDecoration: "none", width: "100%", textAlign: "center" }}
      >
        <Button
          variant="outlined"
          sx={{
            borderColor: "#E85322",
            backgroundColor: "transparent",
            borderRadius: "56px",
            fontSize: "16px",
            lineHeight: "16px",
            fontWeight: 700,
            padding: { lg: "24px 68px", md: "24px 38px", xs: "20px 24px" },
            borderWidth: "2px",
            width: { xs: "100%", md: "auto" },
            maxWidth: "600px",
            margin: "32px auto auto 0px",

            ":hover": {
              borderColor: "inherit",
              backgroundColor: "#E8532212",
              borderWidth: "2px",
            },
          }}
          startIcon={<ButtonDotIcon />}
        >
          {l_manifesto("projectBelievers.buttonText")}
        </Button>
      </a>
    </Box>
  );
};
export default Description;
