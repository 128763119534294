import { Box, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { l_manifesto } from "../../locales";
import ManifestoTypography from "../../theme/ManifestoTypography";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect } from "react";

const Title = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    if (!isOverMd) {
      gsap.to(".scrollingCardsBlueLemon", {
        scrollTrigger: {
          trigger: ".scrollingCardsBlueLemon",
          scrub: 2,
        },
        rotate: 190,
        x: -10,
        y: -40,
      });
    }
  }, [isOverMd]);
  return (
    <Box
      sx={{
        textAlign: { md: "center", xs: "left" },
        margin: "auto",
        maxWidth: "1100px",
        width: "90%",
        marginBottom: { md: "116px", xs: "15px" },
        position: "relative",
      }}
    >
      <ManifestoTypography
        variant="overtitle"
        sx={{ marginBottom: { md: "40px", xs: "8px" } }}
      >
        {l_manifesto("scrollingCards.overtitle")}
      </ManifestoTypography>
      <ManifestoTypography variant="semiTitle">
        {l_manifesto("scrollingCards.title")}
      </ManifestoTypography>
      {!isOverMd && (
        <Box
          sx={{
            position: "absolute",
            width: "30px",
            transform: "rotate(140deg)",
            right: "-2%",
            top: "5%",
          }}
          className="scrollingCardsBlueLemon"
        >
          <StaticImage alt="" src="../../images/blue-lemon.svg" width={30} />
        </Box>
      )}
    </Box>
  );
};

export default Title;
