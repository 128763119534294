import { Box } from "@mui/material";
import { l_manifesto } from "../../locales";
import ManifestoTypography from "../../theme/ManifestoTypography";

const Description = () => {
  return (
    <Box
      sx={{
        flex: 1,
        boxSizing: "border-box",
        display: { md: "block", xs: "flex" },
        flexDirection: "column",
        maxWidth: { md: "900px", xs: "unset" },
      }}
    >
      <ManifestoTypography variant="body1" sx={{ color: "rgba(0,0,0, .6)" }}>
        {l_manifesto("noEndPoint.description")}
      </ManifestoTypography>
    </Box>
  );
};
export default Description;
