import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useEmblaCarousel } from "embla-carousel/react";
import { StaticImage } from "gatsby-plugin-image";
import { useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

//@ts-ignore
import image1 from "../../images/header-carousel-1.png";
//@ts-ignore
import image2 from "../../images/header-carousel-2.png";
//@ts-ignore
import image3 from "../../images/header-carousel-3.png";
//@ts-ignore
import image4 from "../../images/header-carousel-4.png";

const PhotoCarousel = () => {
  const photos = [image1, image2, image3, image4];
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  const isOverLg = useMediaQuery(theme.breakpoints.up("lg"));

  const [emblaRef] = useEmblaCarousel({
    containScroll: "trimSnaps",
    inViewThreshold: 2,
    // draggable: true,
  });

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    gsap.to(".titlePurpleCircle", {
      scrollTrigger: {
        trigger: ".titleCarouselContainer",
        scrub: 2,
        start: "top 70%",
        end: "+=900",
      },
      y: 100,
      x: -100,
    });
  }, []);

  return (
    <Box
      ref={emblaRef}
      sx={{
        maxWidth: "1800px",
        marginX: "auto",
        position: "relative",
        marginBottom: isOverMd ? "80px" : "50px",
        cursor: isOverLg ? "unset" : "grab",
      }}
      className="titleCarouselContainer"
    >
      <Stack
        sx={{
          flexDirection: "row",
          marginX: { lg: "40px", xs: "20px" },
          position: "relative",
          zIndex: 2,
          [theme.breakpoints.up("lg")]: {
            transform: "unset !important",
          },
        }}
      >
        {photos.map((photo, i) => (
          <Stack
            key={photo}
            sx={{
              borderRadius: "24px",
              flex: {
                xs: "0 0 calc(100% - 20px)",
                sm: "0 0 calc(50% - 20px)",
                lg: "0 0 calc(25% - 40px)",
              },
              aspectRatio: "1 / 1.38",
              backgroundImage: `url(${photo})`,
              position: "relative",
              top: `${(i % 2) * (isOverMd ? 80 : 50)}px`,
              marginX: { lg: "20px", xs: "10px" },
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          />
        ))}
      </Stack>
      {isOverMd && (
        <Box
          sx={{
            position: "absolute",
            width: { lg: "50px", md: "25px" },
            top: "20px",
            left: "80%",
          }}
          className="titlePurpleCircle"
        >
          <StaticImage
            src={"../../images/purple-circle.svg"}
            alt={""}
            layout={"constrained"}
            width={60}
          />
        </Box>
      )}
    </Box>
  );
  //  vedere static image in decorations
};

export default PhotoCarousel;
