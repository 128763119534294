import { Typography, TypographyProps } from "@mui/material";
import { forwardRef } from "react";

type ManifestoTypographyProps = {
  variant?:
    | "bigTitle"
    | "title1"
    | "title2"
    | "semiTitle"
    | "overtitle"
    | "body1"
    | "span";
} & Omit<TypographyProps, "variant" | "color">;

const ManifestoTypography = forwardRef<any, ManifestoTypographyProps>(
  ({ variant, sx, ...props }, ref) => {
    return (
      <Typography
        ref={ref}
        component={
          variant === "span"
            ? "span"
            : variant === "bigTitle"
            ? "h1"
            : variant === "title1"
            ? "h2"
            : variant === "title2" || variant === "semiTitle"
            ? "h3"
            : variant === "overtitle"
            ? "h4"
            : "p"
        }
        sx={{
          color: "#000",
          ...(variant === "bigTitle"
            ? {
                fontSize: { xl: "96px", lg: "80px", md: "64px", xs: "40px" },
                lineHeight: { xl: "100px", lg: "84px", md: "68px", xs: "44px" },
                fontWeight: 700,
              }
            : variant === "title1"
            ? {
                fontSize: { lg: "64px", md: "52px", xs: "36px" },
                lineHeight: { lg: "68px", md: "56px", xs: "44px" },
                fontWeight: 700,
              }
            : variant === "title2"
            ? {
                fontSize: { md: "36px", xs: "24px" },
                lineHeight: { md: "44px", xs: "32px" },
                fontWeight: 700,
              }
            : variant === "semiTitle"
            ? {
                fontSize: { lg: "48px", md: "44px", xs: "24px" },
                lineHeight: { lg: "52px", md: "48px", xs: "32px" },
                fontWeight: 700,
              }
            : variant === "body1"
            ? {
                fontSize: { md: "24px", xs: "16px" },
                lineHeight: { md: "32px", xs: "20px" },
                fontWeight: 400,
              }
            : variant === "overtitle"
            ? {
                fontSize: { md: "21px", xs: "16px" },
                lineHeight: { md: "21px", xs: "20px" },
                fontWeight: 700,
                textTransform: "uppercase",
              }
            : // : variant === "title2"
            // ? {
            //     fontSize: "24px",
            //     lineHeight: "32px",
            //     fontWeight: 800,
            //   }
            // : variant === "body2"
            // ? {
            //     fontSize: "20px",
            //     lineHeight: "28px",
            //     fontWeight: 400,
            //     color: "#00000060",
            //   }
            variant === "span"
            ? {
                fontSize: "inherit",
                lineHeight: "inherit",
                fontWeight: "inherit",
              }
            : {}),
          ...sx,
        }}
        {...props}
      />
    );
  }
);
export default ManifestoTypography;
