import { Box, Stack } from "@mui/material";
import Description from "./Description";
import Title from "./Title";

const NoEndPoint = () => {
  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        marginBottom: { md: "280px", xs: "100px" },
      }}
    >
      <Stack
        sx={{
          maxWidth: "1800px",
          width: "90%",
          margin: "auto",
          flexDirection: { md: "row", xs: "column" },
          gap: { lg: "64px", md: "32px", xs: "0px" },
          boxSizing: "border-box",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Title />
        <Description />
      </Stack>
    </Box>
  );
};

export default NoEndPoint;
