import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { l_manifesto } from "../../locales";
import useEmblaCarousel from "embla-carousel-react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

//@ts-ignore
import img1 from "../../images/scrolling-card-1.png";
//@ts-ignore
import img2 from "../../images/scrolling-card-2.png";
//@ts-ignore
import img3 from "../../images/scrolling-card-3.png";
//@ts-ignore
import img4 from "../../images/scrolling-card-4.png";
//@ts-ignore
import img5 from "../../images/scrolling-card-5.png";

import { StaticImage } from "gatsby-plugin-image";

const Card = ({
  title,
  description,
  image,
}: {
  title: string;
  description: string;
  image: string;
}) => {
  return (
    <Box
      sx={{
        position: "relative",
        // backgroundColor: "darkkhaki",
        flex: { xs: "0 0 80%", sm: "0 0 80%", md: "0 0 40%", lg: "0 0 50%" },
        boxSizing: "border-box",
        aspectRatio: { xs: "1 / 1", md: "1 / 1.38", lg: "1 / 1" },
        borderRadius: "24px",
        marginX: { xs: "12px", sm: "16px" },
        display: "flex",
        alignItems: "flex-end",
        maxWidth: "540px",
        maxHeight: "540px",
        cursor: "grab",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          zIndex: 1,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          top: 0,
          left: 0,
          borderRadius: "24px",
          filter: { xs: "invert(15%)", md: "unset" },
        }}
      ></Box>
      <Box
        sx={{
          padding: { xs: "24px", sm: "32px", md: "32px 40px" },
          maxWidth: "400px",
          zIndex: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: "24px",
            lineHeight: "28px",
            color: "#FFF",
            fontWeight: 700,
            marginBottom: "8px",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            lineHeight: "20px",
            color: "#FFF",
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

const DotButton = ({ selected }: { selected?: boolean }) => {
  return (
    <Box
      sx={{
        width: "6px",
        height: "6px",
        borderRadius: "50%",
        transition: "background-color 400ms",
        backgroundColor: selected
          ? "rgb(232, 83, 34)"
          : "rgba(27, 32, 60, 0.12)",
      }}
    ></Box>
  );
};

const CardsSection = () => {
  const [selectedCard, setSelectedCard] = useState(0);
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  const cards = [
    {
      title: l_manifesto("scrollingCards.card1Title"),
      description: l_manifesto("scrollingCards.card1Description"),
      image: img1,
    },
    {
      title: l_manifesto("scrollingCards.card2Title"),
      description: l_manifesto("scrollingCards.card2Description"),
      image: img2,
    },
    {
      title: l_manifesto("scrollingCards.card3Title"),
      description: l_manifesto("scrollingCards.card3Description"),
      image: img3,
    },
    {
      title: l_manifesto("scrollingCards.card4Title"),
      description: l_manifesto("scrollingCards.card4Description"),
      image: img4,
    },
    {
      title: l_manifesto("scrollingCards.card5Title"),
      description: l_manifesto("scrollingCards.card5Description"),
      image: img5,
    },
  ];

  const [emblaRef, emblaApi] = useEmblaCarousel({
    containScroll: "keepSnaps",
    loop: true,
    align: "center",
  });
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    if (isOverMd) {
      gsap.to(".scrollingCardsPurpleCircle", {
        scrollTrigger: {
          trigger: ".scrollingCardsPurpleCircle",
          scrub: 2,
        },
        x: 50,
        y: -100,
      });
    }

    if (emblaApi) {
      emblaApi.on("select", () => {
        setSelectedCard(emblaApi.selectedScrollSnap());
      });
    }
  }, [emblaApi, isOverMd]);

  return (
    <Box
      sx={{
        paddingTop: { md: "40px", xs: "8px" },
        paddingBottom: { md: "220px", xs: "60px" },
        position: "relative",
        // overflow: "hidden",
      }}
      ref={emblaRef}
    >
      <Stack
        sx={{
          flexDirection: "row",
          position: "relative",
          zIndex: 2,
          // marginX: "32px",
          marginBottom: "32px",
        }}
      >
        {cards.map((card, i) => (
          <Card
            key={card.title + i}
            title={card.title}
            description={card.description}
            image={card.image}
          />
        ))}
      </Stack>
      {!isOverMd && (
        <Stack
          sx={{
            flexDirection: "row",
            width: "fit-content",
            margin: "auto",
            gap: "12px",
          }}
        >
          {[...Array(cards.length)].map((_, i) => (
            <DotButton key={i} selected={i === selectedCard} />
          ))}
        </Stack>
      )}
      {isOverMd && (
        <Box
          sx={{ position: "absolute", top: 0, left: "5%", width: "50px" }}
          className="scrollingCardsPurpleCircle"
        >
          <StaticImage alt="" src="../../images/purple-circle.svg" width={55} />
        </Box>
      )}
    </Box>
  );
};

export default CardsSection;
