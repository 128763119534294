import { Box, Stack } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { ReactNode, useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import { l_manifesto } from "../../locales";
import ManifestoTypography from "../../theme/ManifestoTypography";

const GridPoint = ({
  image,
  title,
  description,
  hasBorder,
  id,
}: {
  image: ReactNode;
  title: string;
  description: string;
  hasBorder: boolean;
  id: string;
}) => {
  useEffect(() => {
    gsap.fromTo(
      `.RandDGridPoint${id}`,
      {
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: `.RandDGridPoint${id}`,
          scrub: 2,
          start: "-300px center",
          end: "center center",
        },
        opacity: 1,
      }
    );
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { md: "row", xs: "column" },
        maxWidth: "900px",
        gap: { md: "40px", xs: "24px" },
        borderBottom: hasBorder
          ? "1px solid rgba(0, 0, 0, .06)"
          : "1px solid transparent",
        paddingTop: "32px",
      }}
      className={`RandDGridPoint${id}`}
    >
      <Box
        sx={{
          width: "82px",
          height: "82px",
          backgroundColor: "white",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexShrink: 0,
          padding: "4px",
        }}
      >
        {image}
      </Box>
      <Box>
        <ManifestoTypography variant="title2" sx={{ marginBottom: "4px" }}>
          {title}
        </ManifestoTypography>
        <ManifestoTypography
          variant="body1"
          sx={{
            marginBottom: { md: "32px", xs: "24px" },
            color: "rgba(0, 0, 0, .6)",
          }}
        >
          {description}
        </ManifestoTypography>
      </Box>
    </Box>
  );
};

const TechnologiesGrid = () => {
  const technologies = [
    {
      title: l_manifesto("researchAndDevelopment.gridTitle1"),
      description: l_manifesto("researchAndDevelopment.gridDescription1"),
      image: (
        <StaticImage
          src="../../images/logo-flutter.png"
          alt="Logo Flutter"
          width={82}
        />
      ),
    },
    {
      title: l_manifesto("researchAndDevelopment.gridTitle2"),
      description: l_manifesto("researchAndDevelopment.gridDescription2"),
      image: (
        <StaticImage
          src="../../images/logo-figma.png"
          alt="Logo Figma"
          width={82}
        />
      ),
    },
    {
      title: l_manifesto("researchAndDevelopment.gridTitle3"),
      description: l_manifesto("researchAndDevelopment.gridDescription3"),
      image: (
        <StaticImage
          src="../../images/logo-react.png"
          alt="Logo React"
          width={82}
        />
      ),
    },
    {
      title: l_manifesto("researchAndDevelopment.gridTitle4"),
      description: l_manifesto("researchAndDevelopment.gridDescription4"),
      image: (
        <StaticImage
          src="../../images/logo-node.png"
          alt="Logo Node js"
          width={82}
        />
      ),
    },
  ];

  return (
    <Box>
      <Stack
        sx={{ flexDirection: "column", marginTop: { md: "-32px", xs: 0 } }}
      >
        {technologies.map((tool, i) => (
          <GridPoint
            key={tool.title}
            image={tool.image}
            title={tool.title}
            description={tool.description}
            hasBorder={i !== technologies.length - 1}
            id={i.toString()}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default TechnologiesGrid;
