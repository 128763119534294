import { Box } from "@mui/material";
import { l_manifesto } from "../../locales";
import ManifestoTypography from "../../theme/ManifestoTypography";

const Title = () => {
  return (
    <Box
      sx={{
        width: { lg: "55%", md: "65%", xs: "100%" },
        maxWidth: { lg: "600px", md: "400px", xs: "unset" },
        boxSizing: "border-box",
        marginBottom: { md: 0, xs: "16px" },
      }}
    >
      <ManifestoTypography
        variant="semiTitle"
        // sx={{ fontSize: { lg: "64px", xs: "36px", md: "48px" } }}
      >
        {l_manifesto("aPlaceToBelong.title")}
      </ManifestoTypography>
    </Box>
  );
};
export default Title;
